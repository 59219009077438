import React from "react";
import "../Contact.css";

function Contact() {
  return (
    <div className="contactWrapper">
      <h2>GET IN TOUCH FOR ANY SUPPORT OR INQUIRIES</h2>
      <p>
        After you submit the form, we’ll contact you with a few questions and
        suggest a time to discuss your project in detail!
      </p>
      <form
        action="https://formkeep.com/f/380e99cc6337"
        accept-charset="UTF-8"
        enctype="multipart/form-data"
        method="POST"
      >
        <div className="contactInfoWrapper">
          <label htmlFor="info">CONTACT INFO</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
          />
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
          />
          <input type="text" id="email" name="email" placeholder="Email" />
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone Number"
          />
        </div>
        <div className="serviceInterestWrapper">
          <label id="serviceLabel" htmlFor="services">
            WHAT SERVICES ARE YOU INTERESTED IN?
          </label>
          <div id="serviceOptions">
            <div className="option">
              <input
                className="circle-checkbox"
                type="checkbox"
                name="services"
                value="Plant Adoption"
              />
              <label htmlFor="checkbox">Plant Adoption</label>
            </div>
            <div className="option">
              <input
                className="circle-checkbox"
                type="checkbox"
                name="services"
                value="Paths"
              />
              <label htmlFor="checkbox">Paths</label>
            </div>
            <div className="option">
              <input
                className="circle-checkbox"
                type="checkbox"
                name="services"
                value="Garden Wall"
              />
              <label htmlFor="checkbox">Garden Wall</label>
            </div>
            <div className="option">
              <input
                className="circle-checkbox"
                type="checkbox"
                name="services"
                value="General Gardening"
              />
              <label htmlFor="checkbox">General Gardening</label>
            </div>
            <div className="option">
              <input
                className="circle-checkbox"
                type="checkbox"
                name="services"
                value="Estate Gardening"
              />
              <label htmlFor="checkbox">Estate Gardening</label>
            </div>
            <div className="option">
              <input
                className="circle-checkbox"
                type="checkbox"
                name="services"
                value="Cleanup"
              />
              <label htmlFor="checkbox">Cleanup</label>
            </div>
          </div>
        </div>
        <div className="extraWrapper">
          <label htmlFor="extra">
            ANYTHING ELSE WE SHOULD KNOW ABOUT THE PROJECT?
          </label>
          <textarea
            type="text"
            id="extraField"
            name="extraField"
            placeholder="Message"
          />
          <button id="submitButton" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
