import React from "react";
import "../About.css";
import aboutImage from "../img/IMG_5303.png";

function About() {
  return (
    <div className="aboutWrapper">
      <h2>ABOUT ROOTMATES</h2>
      <img src={aboutImage} alt="" />
      <p>
        Rootmates was founded in 2021 through the journey of mental health and a
        deep passion for forestry. After extensive studies in forestry
        management and extensive hands-on experience, Rootmates found their home
        in Portland, Oregon where they formed a community of helpers to pass on
        their love for gardening and its therapeutic benefits to others.
      </p>
      <button>Request services</button>
    </div>
  );
}

export default About;
