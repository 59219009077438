import React from "react";
import servicesImage1 from "../img/IMG_5209.png";
import servicesImage2 from "../img/IMG_5447.png";
import servicesImage3 from "../img/IMG_8578.png";
import "../Services.css";

function Services() {
  return (
    <div className="servicesWrapper">
      <h2>SERVICES</h2>
      <div
        id="carouselExampleIndicators"
        className="carousel slide servicesCarousel"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={servicesImage1} alt="" />
          </div>
          <div className="carousel-item">
            <img src={servicesImage2} alt="" />
          </div>
          <div className="carousel-item">
            <img src={servicesImage3} alt="" />
          </div>
        </div>
      </div>

      <p>
        Rootmates was founded through the journey of mental health and a deep
        passion for forestry. After extensive studies in forestry management and
        extensive hands-on experience, they formed a community of helpers to
        pass on their love for gardening and its therapeutic benefits to others.
      </p>
      <ul>
        <li>Irrigation</li>
        <li>Bed Creation</li>
        <li>Paths</li>
        <li>Garden Walls</li>
        <li>General Gardening</li>
        <li>Estate Maintenance</li>
      </ul>
      <button>Contact Us</button>
    </div>
  );
}

export default Services;
