import React from "react";
import "../App.css";
import heroImage from "../img/hero.png";
import quoteImage from "../img/quote.png";
// import ig1Image from "../img/ig1.png";
// import ig2Image from "../img/ig2.png";
// import ig3Image from "../img/ig3.png";
import BeholdWidget from "@behold/react";
import icon1 from "../img/icon_plant.png";
import icon2 from "../img/icon_roots.png";
import icon3 from "../img/icon_watering-plants.png";
import icon4 from "../img/icon_hands.png";
import icon5 from "../img/icon_gardening.png";
import icon6 from "../img/icon_growing-seed.png";
import icon7 from "../img/icon_leaves.png";
import icon8 from "../img/icon_growth.png";

function App() {
  return (
    <div className="bodyWrapper">
      <div className="AppWrapper">
        <section className="hero">
          <h1>
            GROW TOGETHER,
            <br /> HEAL TOGETHER
          </h1>
          <p>
            Through personalized gardening support, we nurture healing and
            growth for those facing health challenges in Portland, OR.
          </p>
          <img className="heroImg" src={heroImage} alt="hero" />

          <div className="heroButtonDiv">
            <button className="btn-style">
              <a href="/contact">Contact Us</a>
            </button>
          </div>
        </section>
        <section className="services">
          <h5>SERVICES</h5>
          <h2>WE ARE HERE TO HELP WITH ALL YOUR GARDENING NEEDS SUCH AS...</h2>
        </section>
        <div className="centerWrapper">
          <section className="center">
            <div className="servicesContent">
              <div className="serviceOne serviceCard">
                <img src={icon1} alt="plantIcon" />
                <h6>Irrigation</h6>
                <p>
                  Enhance efficiency with crafted irrigation systems, ensuring
                  optimal water distribution and growth.
                </p>
              </div>
              <div className="serviceTwo serviceCard">
                <img src={icon2} alt="plantIcon" />
                <h6>Bed Creation</h6>
                <p>
                  Transform your outdoor space with artfully crafted garden
                  beds, tailored to enhance the natural beauty of your
                  landscape.
                </p>
              </div>
              <div className="serviceThree serviceCard">
                <img src={icon3} alt="plantIcon" />
                <h6>Paths</h6>
                <p>
                  Craft charming garden paths that guide your journey through
                  nature, adding structure to your outdoor areas.
                </p>
              </div>
              <div className="serviceFour serviceCard">
                <img src={icon4} alt="plantIcon" />
                <h6>Estate Gardening</h6>
                <p>
                  Comprehensive gardening and cleanup services, ensuring a
                  healthy and vibrant outdoor environment.
                </p>
              </div>
            </div>
            <div className="whoItsFor">
              <h5>WHO ITS FOR</h5>
              <h3>A PROGRAM FOR NEW IDEAS</h3>
              <p>
                Rootmates is for anyone seeking personalized gardening services,
                knowing their contribution directly supports mental health and
                environmental organizations.
              </p>
            </div>
            <div className="why">
              <div className="whyText">
                <h5>WHY ROOTMATES</h5>
                <h3>WHAT MAKES US UNIQUE, MAKES YOU UNIQUE</h3>
                <h5 className="whyAbout">
                  <a href="/about">ABOUT ROOTMATES</a>
                </h5>
              </div>
              <div className="growCard whyCard">
                <img src={icon5} alt="plant" />
                <h3>GROW IMPACT & REACH</h3>
                <p>
                  Support more communities, increasing our contributions to
                  mental health charities and making a greater difference
                  together.
                </p>
              </div>
              <div className="collabCard whyCard">
                <img src={icon6} alt="plant" />
                <h3>COLLABORATION & PARTNERSHIPS</h3>
                <p>
                  Support more communities, increasing our contributions to
                  mental health charities and making a greater difference
                  together.
                </p>
              </div>
              <div className="communityCard whyCard">
                <img src={icon7} alt="plant" />
                <h3>COMMUNITY CONNECTIONS</h3>
                <p>
                  Support more communities, increasing our contributions to
                  mental health charities and making a greater difference
                  together.
                </p>
              </div>
              <div className="promoteCard whyCard">
                <img src={icon8} alt="plant" />
                <h3>PROMOTE GROWTH</h3>
                <p>
                  Support more communities, increasing our contributions to
                  mental health charities and making a greater difference
                  together.
                </p>
              </div>
            </div>
            <div className="difference">
              <div className="differenceText">
                <h5>MAKING A DIFFERENCE</h5>
                <h3>HOW YOU CAN HELP</h3>
              </div>
              <div className="volunteerCard differenceCard">
                <h4>VOLUNTEER OPPORTUNITIES</h4>
                <p>
                  Whether you’re a seasoned gardener or just passionate about
                  helping, we have a place for you.
                </p>
                <a href="/contact">
                  <button className="btn-style">Contact Us</button>
                </a>
              </div>
              {/*
              <div className="donationCard differenceCard">
                <h4>DONATION INFORMATION</h4>
                <p>
                  Your donation goes directly toward resources for our gardening
                  services or is given to mental health charities.
                </p>
                <a href="/donate">
                  <button className="btn-style">Donate</button>
                </a>
              </div>
              */}
            </div>
            <div className="love">
              <div className="loveText">
                <h5>WITH LOVE</h5>
                <h3>HEAR FROM OUR MATES</h3>
              </div>
              <div
                id="carouselExampleIndicators"
                className="carousel slide loveCarousel"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    ></li>
                  </ol>
                  <div className="loveCarouselCard carousel-item active">
                    <div className="loveCardInnerWrapper">
                      <div className="loveCardInner">
                        <img
                          className="loveCardImg"
                          src={quoteImage}
                          alt="First Slide"
                        />
                        <p>
                          Rootmates has been a lifesaver, bringing peace and joy
                          during my toughest times. I'm deeply grateful for the
                          compassionate team and the beautiful garden they
                          helped me nurture.
                        </p>
                        <h6>-Ryan S.</h6>
                      </div>
                    </div>
                  </div>
                  <div className="loveCarouselCard carousel-item">
                    <div className="loveCardInnerWrapper">
                      <div className="loveCardInner">
                        <img
                          className="loveCardImg"
                          src={quoteImage}
                          alt="Second Slide"
                        />
                        <p>
                          Rootmates has been a lifesaver, bringing peace and joy
                          during my toughest times. I'm deeply grateful for the
                          compassionate team and the beautiful garden they
                          helped me nurture.
                        </p>
                        <h6>-Ryan S.</h6>
                      </div>
                    </div>
                  </div>
                  <div className="loveCarouselCard carousel-item">
                    <div className="loveCardInnerWrapper">
                      <div className="loveCardInner">
                        <img
                          className="loveCardImg"
                          src={quoteImage}
                          alt="Third Slide"
                        />
                        <p>
                          Rootmates has been a lifesaver, bringing peace and joy
                          during my toughest times. I'm deeply grateful for the
                          compassionate team and the beautiful garden they
                          helped me nurture.
                        </p>
                        <h6>-Ryan S.</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div className="work">
              <div className="workText">
                <h5>OUR WORK</h5>
                <h3>STAY UP TO DATE</h3>
              </div>

              <div className="igCardHolder">
                <BeholdWidget feedId="fnmeTYv8cF9NwEt3mS8w" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;
