import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import App from "./pages/App";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import navImage from "./img/IMG_6716_new.png";

function NavBar() {
  const location = useLocation();

  // set bg color based on the current route
  const navStyle = {
    backgroundColor: location.pathname === "/" ? "#122d18" : "#f2ede1",
    borderBottom: `1px solid ${
      location.pathname === "/"
        ? "rgba(242, 237, 225, 0.1)"
        : "rgb(0, 0, 0, .1)"
    }`,
  };

  const navButtonStyle = {
    backgroundColor: location.pathname === "/" ? "#ffc364" : "#122d18",
  };

  const navButtonLinkStyle = {
    color: location.pathname === "/" ? "#122d18" : "#f2ede1",
  };

  const navLinkStyle = {
    color: location.pathname === "/" ? "#f2ede1" : "#122d18",
  };

  //const navLogoStyle = {
  //color: location.pathname === "/" ? "#f2ede1" : "#3c4339",
  //};

  const dropdownButtonStyle = {
    color: location.pathname === "/" ? "#f2ede1" : "#3c4339",
  };

  return (
    <nav style={navStyle} className="nav">
      <Link id="navLogo" to="/">
        <div className="navImageDiv">
          <img src={navImage} alt="" />
        </div>
      </Link>
      {/* <p>
        <Link style={navLogoStyle} id="navLogo" to="/">
          Rootmates
        </Link>
      </p>*/}
      <div className="navButtons">
        <Link style={navLinkStyle} to="/">
          Home
        </Link>
        <Link style={navLinkStyle} to="/services">
          Our Services
        </Link>
        <Link style={navLinkStyle} to="/about">
          About
        </Link>
      </div>
      <div className="dropdownNavButton">
        <button
          className="btn btn-secondary dropdown-toggle"
          style={dropdownButtonStyle}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          ☰
        </button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <Link className="dropdown-item" to="/services">
            Our Services
          </Link>
          <Link className="dropdown-item" to="/about">
            About
          </Link>
          {/* <Link className="dropdown-item" to="/donate">
            Donate
          </Link>*/}
          <Link className="dropdown-item" to="/contact">
            Contact
          </Link>
        </div>
      </div>
      <div className="contactUsButtonDiv">
        <button style={navButtonStyle} className="btn-style">
          <Link style={navButtonLinkStyle} to="/contact">
            Contact Us
          </Link>
        </button>
      </div>
    </nav>
  );
}

function NewApp() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          {/*          <Route path="/donate" element={<Donate />} />
           */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <footer>
          <div className="headerBlock">
            <h2>WE ARE HERE TO HELP YOUR GARDEN</h2>
            <button className="btn-style footerButton">
              <Link id="footerButtonLink" to="/contact">
                Contact Us
              </Link>
            </button>
          </div>
          <div id="footerBlock">
            <ul>
              <div className="leftFooterUl">
                <Link to="/">
                  <li>HOME</li>
                </Link>
                <Link to="/services">
                  <li>SERVICES</li>
                </Link>
                <Link to="/about">
                  <li>ABOUT</li>
                </Link>
                <Link
                  to="https://www.instagram.com/root.mates/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>INSTAGRAM</li>
                </Link>
                {/*<Link to="/donate">
                  <li>DONATE</li>
                </Link>*/}
              </div>
              <div className="rightFooterUl"></div>
            </ul>
          </div>
          <div id="footerBlockBottom">
            <img className="footerLogo" src={navImage} alt="" />
            <h6>2024 ROOTMATES</h6>
          </div>
          <a
            id="attribution"
            href="https://www.flaticon.com/free-icons/grow"
            title="grow icons"
            target="_blank"
            rel="noopener noreferrer"
          >
            Grow icons created by Freepik - Flaticon
          </a>
        </footer>
      </div>
    </Router>
  );
}

export default NewApp;
